import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      formLoading: false,
      formError: false,
      formSuccess: false,
      formData: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    this.setState({ formError: false, formSuccess: false });

    if (this.state.formData === null) {
      this.setState({ formError: true });
      return;
    }
    if (this.state.formData?.contactMessage) {
    } else {
      this.setState({ formError: true });
      return;
    }

    if (this.state.formData?.contactName) {
    } else {
      this.setState({ formError: true });
      return;
    }

    if (this.state.formData?.contactEmail) {
    } else {
      this.setState({ formError: true });
      return;
    }
    this.setState({ formLoading: true });

    setTimeout(() => {
      const xhr = new XMLHttpRequest();

      try {
        xhr.open("POST", `https://script.google.com/macros/s/AKfycbxrMIV02ovZk6f_AaCPC9So3UbJEKuE79tTI9fwZm7L5UTkRix1ML5PPgIT8MUkQSCvgg/exec`);
        // send the request
        xhr.send(JSON.stringify(this.state.formData));
        this.setState({ formSuccess: true });
        this.setState({ formLoading: false });
      } catch (err) {
        this.setState({ formError: true });
      }
    }, 1000);
  }

  handleChange(e) {
    this.setState({ formData: { ...this.state.formData, [e.target.id]: e.target.value } });
    console.log(this.state);
  }

  render() {
    if (!this.props.data) return null;
    const message = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="ten columns">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">Subject</label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      onChange={this.handleChange}
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                    ></textarea>
                  </div>

                  <div>
                    <button onClick={this.handleSubmit} className="submit">
                      Submit
                    </button>
                    <span id="image-loader" style={{ display: this.state.formLoading ? "" : "none" }}>
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div style={{ display: this.state.formError ? "" : "none" }} id="message-warning">
                Opps, something went wrong, please try again later
              </div>
              <div style={{ display: this.state.formSuccess ? "" : "none" }} id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="two columns footer-widgets">
              <div className="widget widget_contact">
                <h4>My Email address</h4>
                <p className="address">matisssvikisss@gmail.com</p>
              </div>
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
