import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            Three years of {education.degree}
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>Algorithms and programming:</b> basics of C++ and basics of object oriented programming</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>Web technologies:</b> Html, css, javascript, bootstrap, php, laravel</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>Software development basics:</b> C++ data structures and work with files</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>Databases:</b> mysql, microsoft sql server management studio 18 and NoSQL</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>Application development in .NET environment:</b> C# basics, and application development using wpf
              technologies</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>Data structures and Algorithms:</b> various problem solving with data structures in C++</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date"><b>And many more...</b></em>
          </p>
        </div>
      );
    });

    const Work = () => {
      return (
        <div>
          <h3>Visma</h3>
          <p className="info">
          Software Developer <span>&bull;</span> <em className="date">February 2020 - Present</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">In-house tool creation & maintenance</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">Created various UI pages based on designers work using React & back-end functionality using nodejs</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">New microservice creation & support using C# .Net & deployment to AWS</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">C# Unit testing & e2e tests using Selenium framework</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">Code reviews</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">Legacy web app maintenance and development</em>
          </p>
          <p className="info">
            <span>&bull;</span>
            <em className="date">Developed a parking reservation web app with JavaScript, HTML, CSS and NoSQL firebase database</em>
          </p>
        </div>
      );
    };

    // const work = this.props.data.work.map(function (work) {
    //   return (
    //     <div key={work.company}>
    //       <h3>{work.company}</h3>
    //       <p className="info">
    //         {work.title}
    //         <span>&bull;</span> <em className="date">{work.years}</em>
    //       </p>
    //       <p>{work.description}</p>
    //     </div>
    //   );
    // });

    // const skills = this.props.data.skills.map((skills) => {
    //   const backgroundColor = this.getRandomColor();
    //   const className = "bar-expand " + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className}></span>
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    return (
      <section id="resume">


        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Work</span>
              </h1>
            </div>

            <div className="nine columns main-col"><Work/></div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Education</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
