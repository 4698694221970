import React from "react";
import matiss from "./matiss.module.css";
import img from "./img_avatar2.png";
const CardExampleImageCard = (props) => {
  const { title, description, image } = props;

  return (
    <div className={matiss.card}>
      <img src={image} alt="Avatar" className={matiss.cardImg} style={{ width: "100%" }} />
      <div className={matiss.container}>
        <h4>
          <b>{title}</b>
        </h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default CardExampleImageCard;
