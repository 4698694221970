import React, { Component } from "react";
import PostCard from "./PostCard";
import { Grid } from "@material-ui/core";
import simpleMazeGame from "./simpleMazeGame.gif";
import bookNdrop from "./bookndrop1.gif";
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="portfolio">
        <Grid container spacing={6} style={{ paddingRight: "10%", paddingLeft: "10%", width: "100%" }}>
          <Grid item xs={4}>
            <PostCard title="Simple maze game" description="A simple game made with purely Javascript" image={simpleMazeGame} />
          </Grid>
          <Grid item xs={4}>
            <PostCard
              title="Parking reservation web app"
              description="A parking reservation web app made in google apps script platform with vanilla javascript and html web components. Database integration with Firebase"
              image={bookNdrop}
            />
          </Grid>
          <Grid item xs={4}>
            <PostCard />
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default Portfolio;
